<template>
  <div>
    <div class="loading-spinner" v-if="show">
      <div class="loading-spinner-div" style="margin-top:auto;height:100%">
        <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="60" :color="$root.colorLoading"/>
      </div>
    </div>
    <b-card>
      <h5 class="fontWeightBold mb-4">کالاها</h5>
      <!-- <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute"
          style="top:20px; left: 20px;" size="sm"><i class="fa fa-bars"></i></b-button> -->
      <b-collapse :visible="true" id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="filter" id="search-item">
            <div class="row">
              <div class="col-md-4">
                <b-form-group label="جستجوی عنوان کالا" label-for="title">
                  <b-form-input placeholder="عنوان کالا" id="title" v-model="searchParams.title"></b-form-input>
                </b-form-group>
              </div>

            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو
            </button>
          </form>
        </b-card>
      </b-collapse>

      <b-table v-if="items.data" responsive striped outlined hover :fields="fields" :items="items.data.data">
        <template v-slot:cell(status)="data">
          <!-- <b-form-checkbox v-model="data.item.status" :disabled="disabled" @input="activeItem(data.index)"
              size="lg" switch>
          </b-form-checkbox> -->
          <label class="custom-switch">
            <input type="checkbox" name="status" class="custom-switch-input" v-model="data.item.status"
                   :disabled="disabled">
            <span class="custom-switch-indicator"></span>
            <span class="custom-switch-description"></span>
          </label>
        </template>
        <template v-slot:cell(more)="data">
          <router-link class="btn btn-info" :to="{name: 'panel.product.detail' , params:{id:data.item.id}}">
            <i class="fas fa-ellipsis-v"></i>
          </router-link>
        </template>
      </b-table>
      <div class="alert alert-danger" v-if="items.data && items.data.data == ''">
        <span>موردی یافت نشد ! </span>
      </div>
      <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
      </pagination>
      <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
      </pagination>
    </b-card>
  </div>
</template>
<script>
import mixins from '../mixins/mixins'

export default {
  mixins: [mixins],
  data() {
    return {
      page: 1,
      title: 'کالا ها',
      url: '/api/seller/products',
      fields: [{
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'title',
          label: 'عنوان کالا'
        },
        // {
        //     key: 'status',
        //     label: 'وضعیت'
        // },
        {
          key: 'in_storage',
          label: 'تعداد کالا'
        },
        {
          key: 'sales_count',
          label: 'تعداد فروش'
        },
        {
          key: 'more',
          label: ''
        },
      ]
    }
  },
  methods: {
    filter() {
      this.q = this.searchParams.title
      this.loadItems()
    },
    loadItems(page = 1) {
      this.show = true
      this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page, {
        params: {
          q: this.q
        }
      }).then(response => {
        this.items = response.data
        this.show = false
      })
    },
  },
  mounted() {
    this.loadItems()
    this.searchParams = {
      title: '',
      status: ''
    }
  }
}
</script>
<style>
.panel .custom-switch-input:checked ~ .custom-switch-indicator {
  background: rgb(0, 230, 130);
}

.panel .custom-switch-input:focus ~ .custom-switch-indicator {
  box-shadow: 0 0 0 2px rgb(0, 230, 130, 0.25);
}

.panel .custom-switch-input:focus ~ .custom-switch-indicator {
  border-color: #00e682;
}
</style>
